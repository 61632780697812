import { debounce } from "@/utils/bouncer";
import { createLogger } from "@paparazzi/utils/debug";
import api from "@virgodev/bazaar/functions/api";
import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";

const log = createLogger("timesync");

export const useTimesyncStore = defineStore("timesync", () => {
  const promise: Ref<Promise<number> | null> = ref(null);
  const lastAttempt = ref(0);
  const offset = ref<number>(5 * 60 * 1000);
  const now = ref<number>(Date.now());
  const ms = ref<number>(Date.now());

  const needsTimeSync = computed(() => {
    return Math.abs(offset.value) > 1000 * 60 * 6;
  });

  const seconds = computed(() => {
    return Math.ceil(offset.value / 1000);
  });

  async function calculate(force = false) {
    if (force || Date.now() - lastAttempt.value > 1000 * 60 * 60 * 2) {
      if (await debounce("timesync-calculate")) {
        lastAttempt.value = Date.now();
        promise.value = (async () => {
          let start = Date.now(); // - 1000 * 60 * 60;
          let servertime = null;
          let response = await api({ url: "profile/time/" });
          let retval = NaN;
          if (response.ok) {
            servertime = response.body.time * 1000;
          }
          if (servertime) {
            let end = Date.now(); // - 1000 * 60 * 60;
            let trip = Math.max(0, Math.min(30000, end - start));
            retval = servertime - end + trip / 2;
            log("time offset", `${Math.floor(retval / 1000)}s`);
          }
          offset.value = retval;
          return retval;
        })();
      }
    }
    return promise.value;
  }

  setInterval(() => {
    const prev = now.value;
    now.value = Date.now();
    const diff = Math.abs(now.value - prev);
    if (diff > 1000 * 60) {
      log("timejump detected", diff);
      calculate(true);
    }
  }, 500);

  setInterval(() => (ms.value = Date.now()), 30);

  return { now, offset, seconds, needsTimeSync, ms, calculate };
});
