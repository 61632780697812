<template>
  <div class="category-list">
    <p v-if="props.options.categories.length === 0">
      <i>Choose categories...</i>
    </p>
    <h4 v-if="props.options.title">{{ props.options.title }}</h4>

    <div class="categories-container">
      

      <div class="categories" ref="list">
        <RouterLink
          v-for="item of categories"
          :key="item.category.id"
          :to="{ name: 'shop', params: { path: item.category.slug } }"
          class="category"
        >
          <AutoThumbnail
            v-if="item.image"
            :src="item.image"
            :alt="`${item.category.name} Paparazzi Accessories category jewelry`"
          />
          <div v-else class="colorbox"></div>
          <div class="label">{{ item.category.name }}</div>
        </RouterLink>
      </div>

    </div>
  </div>
</template>

<script setup>
import { useShopStore } from "@/stores/shop";
import AutoThumbnail from "paparazzi/components/blog/AutoThumbnail.vue";
import { computed, ref } from "vue";

const props = defineProps({
  options: Object,
});
const shop = useShopStore();

const list = ref()
const scrollAmount = ref(0);

const categories = computed(() => {
  return props.options.categories
    .map((cat) => {
      return {
        image: cat.image,
        category: shop.categories.find((c) => c.id === cat.category),
      };
    })
    .filter((a) => a.category);
});

const bg = computed(() => `#${props.options.bg}`);
const defaultColor = computed(() => `#${props.options.defaultColor}`);

function scrollLeft() {
  list.value.scrollLeft -= 200
}
function scrollRight() {
  list.value.scrollLeft += 200
}

</script>

<style scoped>
.category-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  align-items: flex-start;
  align-self: stretch;
  background-color: v-bind("bg");
  margin: auto;
  max-width: var(--Max-page-widths-max-width);
}
.category-list h4 {
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.categories-container {
  position: relative;
  overflow-x: hidden;
  width: 100%;
}
.categories {
  display: grid;
  grid-template-columns: repeat(v-bind("categories.length"), 1fr);
  grid-template-rows: 1fr;
  gap: 24px;
  overflow-x: auto;
  max-width: 100%;
  a {
    color: var(--Black, var(--Black, #000));
    transition: 0.2s ease;
    font-family: Indivisible;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-shadow: none;
  }
  a:hover {
    font-weight: 400;
    text-shadow: #000 0 0.25px;
  }
}
.category .colorbox,
.category img {
  min-width: 160px;
  width: 167px;
  height: 167px;

  aspect-ratio: 1/1;
  background-color: v-bind("defaultColor");
  object-fit: cover;
}

.left-scroll, .right-scroll {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  z-index: 4;
  margin-inline: 16px;

  border: none;
  background-color: black;
  color: white;
  transition: all 0.15s;
}

.left-scroll:hover, .right-scroll:hover {
  background-color: gray;
}

.left-scroll {
  left: 0;
}
.right-scroll {
  right: 0;
}

@media (max-width: 1120px) {
  .category:first-of-type {
    padding-left: var(--Sm, 24px);
  }
  .category:last-of-type {
    padding-right: var(--Sm, 24px);
  }
}

@media (max-width: 800px) {
  .left-scroll, .right-scroll {
    display: none;
  }
  .category .colorbox,
  .category img {
    min-width: 122px;
    width: var(--Component-sizes-2XS, 122px);
    aspect-ratio: 1/1;
    height: 122px;
  }
}

.label {
  color: var(--Black, #000);
  text-align: center;
  font-family: Indivisible;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 6px;
}
.category:hover .label {
  font-weight: 400;
}
</style>
