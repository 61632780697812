import {
  colorLabels,
  extraIdentifiers,
  typeOptions,
  type Product,
} from "@/stores/defs/shop_defs";
import { useShopStore } from "@/stores/shop";
import api from "@virgodev/bazaar/functions/api";
import { destem } from "./utilities";

export interface ProductErrorMessage {
  level: string;
  message: string;
  oos: boolean;
  product: Product;
  products: Product[];
}

export function getCategoryLabels(product: Product): string[] {
  const shop = useShopStore();
  const categories = shop.objects.categories.filter((c) =>
    product.categories.includes(c.id),
  );
  const retval = [] as string[];

  // // FOR DEBUGGING
  // if (localStorage.tester) {
  //   for (const cat of categories) {
  //     retval.push(
  //       "*" +
  //         shop.ancestors[cat.id].map((c) => `[${c.id}] ${c.name}`).join(" > "),
  //     );
  //   }
  // }

  for (const cat of categories) {
    const name = cat.name.replace("Accessories", "").trim();
    if (colorLabels.includes(name)) {
      retval.push(name);
    }
  }

  let foundType = false;
  for (const cat of categories) {
    for (const ancestor of shop.ancestors[cat.id]) {
      if (typeOptions.includes(ancestor.name)) {
        retval.push(destem(ancestor.name));
        foundType = true;
        break;
      }
    }
    if (foundType) {
      break;
    }
  }

  for (const cat of categories) {
    for (const ancestor of shop.ancestors[cat.id]) {
      if (
        extraIdentifiers.includes(ancestor.name) &&
        !retval.includes(ancestor.name)
      ) {
        retval.push(ancestor.name);
      }
    }
  }
  return retval;
}

const MINUTES = 60 * 1000;
export var timeComponents: [string, number][] = [
  ["day", 24 * 60 * 60 * 1000],
  ["hour", 60 * 60 * 1000],
  ["minute", MINUTES],
  ["second", 1000],
];

export function formatInterval(milli: number) {
  milli = Math.floor(milli);

  if (milli > 1000 * 60 * 5) {
    for (let key in timeComponents) {
      let [component, interval] = timeComponents[key];
      if (milli > interval) {
        const value = Math.ceil(milli / interval);
        let label = component;
        if (value !== 1) {
          label = component + "s";
        }
        return `${value} ${label}`;
      }
    }
  }
  const minutes = Math.floor(milli / MINUTES);
  milli -= minutes * MINUTES;
  const seconds = "" + Math.floor(milli / 1000);
  return `${minutes}:${seconds.padStart(2, "0")}`;
}

export async function checkForProduct(
  slug: string,
): Promise<Product | undefined> {
  const shop = useShopStore();
  const response = await api({
    url: `products/${slug}/`,
  });

  // make sure the item hasn't been added while the api was waiting for a response
  if (response.ok) {
    const hidden2 = shop.objects.products.find((p) => p.slug === slug);
    if (!hidden2) {
      console.log("-> api response", response.body);
      shop.objects.products.push(
        shop.patchers.products.migrateItem(response.body) as Product,
      );
    } else {
      console.log("item found while api ran", slug);
    }
  } else {
    console.log(
      "failed to get item on api",
      slug,
      response.status,
      response.body,
    );
  }
  return shop.objects.products.find((p) => p.slug.toLowerCase() === slug);
}
