import AnnouncementBlock from "@/components/AnnouncementBlock.vue";
import CategoryList from "@/components/CategoryList.vue";
import CarouselPaparazziEdit from "@/components/layouts/CarouselPaparazziEdit.vue";
import CarouselView from "@/components/layouts/CarouselView.vue";
import TextEdit from "@/components/layouts/TextEdit.vue";
import TextView from "@/components/layouts/TextView.vue";
import ProductBanner from "@/components/ProductBanner.vue";
import type { ManifestIndex } from "@virgodev/web-builder/utils/layout";

export const manifest: ManifestIndex = {
  announce: {
    view: AnnouncementBlock,
    form: [
      { name: "title", label: "Title", type: "text" },
      { name: "short", label: "Short Content", type: "textarea" },
      { name: "prompt", label: "Button Text", type: "text" },
      { name: "link", label: "Link", type: "text" },

      {
        name: "align",
        label: "Content Alignment",
        options: [
          { name: "left", value: "left" },
          { name: "right", value: "right" },
          { name: "center", value: "center" },
        ],
      },
      {
        name: "mobile_align",
        label: "Mobile Alignment",
        options: [
          { name: "left", value: "left" },
          { name: "right", value: "right" },
          { name: "center", value: "center" },
          { name: "below", value: "below" },
        ],
      },
      {
        name: "size",
        label: "Component Size",
        options: [
          { name: "XL", value: "xl" },
          { name: "Large", value: "large" },
          { name: "Normal", value: "normal" },
        ],
      },
      {
        name: "position",
        label: "Override Text Position",
        options: [
          { name: "Yes", value: "relative" },
          { name: "No", value: "initial" },
        ],
      },
      {
        name: "position_relative_top",
        label: "Top",
        type: "number",
      },
      {
        name: "position_relative_right",
        label: "Right",
        type: "number",
      },
      {
        name: "position_relative_bottom",
        label: "Bottom",
        type: "number",
      },
      {
        name: "position_relative_left",
        label: "Left",
        type: "number",
      },
      {
        name: "show_text_mobile",
        label: "Show text on mobile",
        type: "checkbox",
      },
      {
        name: "title_text_width",
        label: "Title Text Width",
        type: "number",
      },
      {
        name: "title_max_width",
        label: "Title Max Width",
        type: "number",
      },
      {
        name: "short_text_width",
        label: "Short Text Width",
        type: "number",
      },

      {
        name: "background",
        group: "Background",
        label: "Background Image",
        type: "image",
      },
      {
        name: "background_mobile",
        group: "Background",
        label: "Mobile Background Image",
        type: "image",
      },
      {
        name: "bg",
        group: "Background",
        label: "Background",
        type: "color",
        default: "ffffff",
      },
      {
        name: "bg_alignment",
        group: "Background",
        label: "Background Alignment",
        type: "range",
        min: 0,
        max: 1,
        step: 0.01,
      },
      {
        name: "bg_justify",
        group: "Background",
        label: "Background Justification",
        type: "range",
        min: 0,
        max: 1,
        step: 0.01,
      },

      {
        name: "use_content_bg",
        group: "Content Background",
        label: "Use Content Background?",
        type: "checkbox",
      },
      {
        name: "content_bg",
        group: "Content Background",
        label: "Content Background",
        type: "color",
        default: "000000",
      },
      {
        name: "content_mobile_bg",
        group: "Content Background",
        label: "Content Mobile Background",
        type: "color",
      },
      {
        name: "content_bg_opacity",
        group: "Content Background",
        label: "Content Background Opacity",
        type: "range",
        min: 0,
        max: 1,
        step: 0.01,
      },
      {
        name: "content_fg",
        group: "Content Background",
        label: "Content Text",
        type: "color",
        default: "000000",
      },
      {
        name: "content_mobile_fg",
        group: "Content Background",
        label: "Content Mobile Text",
        type: "color",
        default: "000000",
      },
      {
        name: "content_button_style",
        group: "Content Background",
        label: "Content Button Style",
        options: [
          { name: "normal", value: "normal" },
          { name: "white", value: "white" },
          { name: "black", value: "black" },
        ],
      },
      {
        name: "content_mobile_button_style",
        group: "Content Background",
        label: "Content Mobile Button Style",
        options: [
          { name: "normal", value: "normal" },
          { name: "white", value: "white" },
          { name: "black", value: "black" },
        ],
      },
    ],
    defaults: {
      bg_alignment: 0.5,
      bg_justify: 0.5,
      bg_opacity: 0.2,
      content_bg_opacity: 0.8,
      title_text_width: 188,
      title_max_width: 260,
      short_text_width: 188,
      bg: "FFC0CB",
      use_content_bg: true,
      content_bg: "222222",
      content_fg: "eeeeee",
      align: "right",
      mobile_align: "center",
      prompt: "Learn more",
      size: "normal",
      position: "initial",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  "category-list": {
    view: CategoryList,
    form: [
      { name: "title", label: "Title" },
      { name: "bg", label: "Background", type: "color" },
      {
        name: "categories",
        type: "inline",
        fields: [
          { name: "category", label: "Category", type: "category" },
          { name: "image", label: "Image", type: "image" },
        ],
      },
    ],
    defaults: {
      categories: [],
    },
  },
  "product-banner": {
    view: ProductBanner,
    form: [
      { name: "title", label: "Title" },
      { name: "bg", label: "Background", type: "color" },
      {
        name: "show",
        label: "Show",
        options: [{ name: "Oldest", value: "oldest" }],
      },
      {
        name: "categories",
        type: "inline",
        fields: [
          { name: "category", label: "Category", type: "category" },
          { name: "image", label: "Image", type: "image" },
        ],
      },
    ],
    defaults: {
      show: "oldest",
      categories: [],
    },
  },
  carousel: {
    view: CarouselView,
    build: CarouselPaparazziEdit,
    defaults: {
      pages: [{ id: 1, header: [], layout: [] }],
    },
  },
  text: {
    label: "WYSIWYG Editor",
    view: TextView,
    build: TextEdit,
    form: [
      { name: "title", label: "Title" },
      { name: "text", label: "Text", type: "textarea" },
    ],
  },
};
