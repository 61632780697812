<template>
  <Editor v-model="text" auto-resize editorStyle="height: 320px" />
</template>

<script setup lang="ts">
import Editor from "primevue/editor";
import { computed } from "vue";

const props = defineProps({
  options: { type: Object, required: true },
});
const emits = defineEmits<{
  update: [{ text: string }];
}>();
const text = computed({
  get() {
    return props.options.text;
  },
  set(v) {
    emits("update", { text: v });
  },
});
</script>

<style scoped></style>
