<template>
  <Carousel :list="list" hide-beads>
    <template #header="{ item, index }">
      <LayoutViewer :layout="item.header" :manifest="layouts.manifest" />
    </template>
    <template #body="{ item }">
      <LayoutViewer :layout="item.layout" :manifest="layouts.manifest" />
    </template>
  </Carousel>
</template>

<script setup lang="ts">
import LayoutViewer from "@virgodev/web-builder/components/LayoutViewer.vue";
import { useLayoutStore } from "@virgodev/web-builder/stores/layouts";
import { computed } from "vue";
import Carousel from "./Carousel.vue";
import type { CarouselOptions } from "./CarouselPaparazziEdit.vue";

const layouts = useLayoutStore();

const props = defineProps<{
  options: CarouselOptions;
}>();

const list = computed(() => {
  return props.options.pages;
});
</script>

<style scoped></style>
