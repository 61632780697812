<template>
  <div id="app" v-if="sitelock.permitted">
    <div v-if="user.isAdmin || user.isLocalhost" class="relative">
      <div id="editing" class="flex-row p-2xs gap-2xs">
        <a v-if="user.isAdmin" href="/admin/monitor/">
          <Button aria-label="Navigate to Management Area">
            <i class="pi pi-pen-to-square" /> Admin
          </Button>
        </a>
        <ToggleButton
          v-if="
            ['home', 'notices'].includes((route.name || '').toString()) &&
            user.isAdmin
          "
          v-model="layouts.editing"
          on-label="Editing"
          off-label="Edit Page"
          aria-label="Toggle editing mode"
        />
        <ToggleButton
          v-model="user.devActive"
          on-label="Debug"
          off-label="Normal"
          aria-label="Toggle debug mode"
        />
      </div>
      <div
        v-if="user.isAdmin"
        id="imp"
        class="flex-row absolute top-zero right-zero z-2 p-2xs"
      >
        <ImpersonatePanel />
      </div>
    </div>
    <AppHeader />
    <div id="app-content" :class="{ container }">
      <div id="ssr-ready" v-if="ready">ssr ready:</div>
      <div id="ssr-not-ready" v-else>waiting for ssr:</div>
      <!-- <div>
        needs: {{ ssr.needs }} --
        ssr: {{ ssr.ready }} --
        ready: {{ ready }} --
        fallback: {{ fallback }} --
      </div> -->
      <RouterView class="page" />
    </div>
    <AppFooter />
    <Toast position="bottom-right" />
    <div id="page-info">{ "file": "{{ filename }}" }</div>
  </div>
  <SiteLock v-else />
</template>

<script setup lang="ts">
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import { useCartStore } from "@/stores/cart";
import { useSocketStore } from "@/stores/socket";
import { useSSRStore } from "@/stores/ssr";
import { useUserStore } from "@/stores/user";
import { resetModifications } from "@virgodev/bazaar/functions/api";

import { useHead } from "@unhead/vue";
import { useLayoutStore } from "@virgodev/web-builder/stores/layouts";
import Button from "primevue/button";
import Toast from "primevue/toast";
import ToggleButton from "primevue/togglebutton";
import { computed, onMounted, onUnmounted, ref, watch, watchEffect } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import ImpersonatePanel from "./components/ImpersonatePanel.vue";
import PaparazziForm from "./components/PapaAutoForm.vue";
import SiteLock from "./components/SiteLock.vue";
import { useAddressStore } from "./stores/address";
import { useCardsStore } from "./stores/cards";
import { useCheckoutStore } from "./stores/checkout";
import { useFirebaseStore } from "./stores/firebase";
import { usePromosStore } from "./stores/promos";
import { useShopStore } from "./stores/shop";
import {
  checkVersion,
  executeServerCommand,
  getVersion,
} from "./stores/utils/commands";
import { manifest } from "./utils/layout_manifest";
import { loadBeacon } from "./utils/loadBeacon";
import { useSiteLockStore } from "./utils/site_lock";

const addresses = useAddressStore();
const cards = useCardsStore();
const cart = useCartStore();
const checkout = useCheckoutStore();
const firebase = useFirebaseStore();
const promos = usePromosStore();
const route = useRoute();
const router = useRouter();
const shop = useShopStore();
const sitelock = useSiteLockStore();
const socket = useSocketStore();
const ssr = useSSRStore();
const user = useUserStore();
const layouts = useLayoutStore();

layouts.manifest = manifest;
layouts.formBuilder = PaparazziForm;

const ready = ref(false);
const fallback = ref(false);
let readyTimeout = -1;

const container = computed(() => {
  return !route.meta?.fullWidth;
});

const filename = computed(() => {
  if (route.matched && route.matched.length > 0) {
    const myroute = route.matched[0];
    if (myroute.components?.default.__file) {
      return myroute.components.default.__file.split("/src/")[1];
    }
  }
  return "";
});

watch(
  () => ssr.needs,
  () => {
    if (ssr.ready) {
      clearTimeout(readyTimeout);
      readyTimeout = window.setTimeout(() => {
        ready.value = true;
        fallback.value = false;
      }, 1000);
    }
  },
  { deep: true },
);

watchEffect(() => {
  if (user.isRep && /paparazzi/.test(location.host)) {
    loadBeacon();
  }
});

useHead({
  titleTemplate: "%s | Paparazzi Accessories",
});

onMounted(async () => {
  clearTimeout(readyTimeout);
  readyTimeout = window.setTimeout(() => {
    ready.value = true;
    if (!ssr.ready) {
      fallback.value = true;
    }
  }, 15000);

  await socket.setup();
  await firebase.setup();
  await Promise.all([
    shop.setup(),
    cart.setup(),
    promos.setup(),
    addresses.setup(),
    cards.setup(),
    checkout.setup(),
  ]);

  if (!user.isTester) {
    // location = 'https://paparazziaccessories.com'
  }

  window.addEventListener("visibilitychange", checkVersion);

  // check if already logged in with cookies
  if (!user.props.id) {
    const loggedIn = await user.checkLogin();
    if (loggedIn) {
      if (router.currentRoute.value.name === "account-signin") {
        await router.push({ name: "account-profile" });
      }
    }
  } else {
    await user.pull();
  }

  socket.on("cmd", executeServerCommand);
  if (!localStorage.versionHash) {
    window.versionHash = await getVersion();
    localStorage.versionHash = window.versionHash;
  } else {
    window.versionHash = localStorage.versionHash;
  }
});

onUnmounted(() => {
  resetModifications();
  window.removeEventListener("visibilitychange", checkVersion);
});
</script>

<style scoped>
#ssr-ready,
#ssr-not-ready {
  position: absolute;
  bottom: 0;
  right: 0;
  color: transparent;
}
#editing {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
#imp {
  z-index: 1000;
}

.page {
  margin: var(--1XL, 64px) auto;
  margin-top: calc(var(--1XL) / 2);
}

#page-info {
  display: none;
}

@media (max-width: 800px) {
  #app-content.container {
    padding: 0 var(--Sm, 24px);
  }
  .page {
    margin: var(--Mobile-main-gap, 40px) auto;
  }
  .content-page {
    display: block;
  }
}
</style>
