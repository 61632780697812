<template>
  <div class="p-xs-x" v-if="list && list.length > 0">
    <div class="header">
      <div class="flex-row flex-center">
        <div class="flex-column flex-center">
          <a v-if="list.length > 1" href="#" @click.prevent="prev">
            <i class="pi pi-chevron-left" />
          </a>
        </div>

        <div class="p-sm block" v-if="listItem">
          <slot name="header" :item="listItem" :index="current">
            <h2>Carousel</h2>
          </slot>
        </div>

        <div class="flex-column flex-center">
          <a v-if="list.length > 1" @click="next" href="#">
            <i class="pi pi-chevron-right" />
          </a>
        </div>
      </div>
      <div v-if="!props.hideBeads" class="flex-row flex-center">
        <div v-if="list.length > 1" class="dots">
          <a
            v-for="(promo, index) in list"
            :key="promo.id"
            href="#"
            @click.prevent="current = index"
            class="p-xs"
          >
            <div
              class="pi pi-circle"
              :class="{ 'pi pi-circle-fill': index === current }"
            ></div>
          </a>
        </div>
      </div>
    </div>
    <div v-if="listItem">
      <slot name="body" :item="listItem" :index="current">
        {{ listItem }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends { id: number | string } = any">
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps<{
  list: T[];
  hideBeads?: boolean;
}>();

const current = ref(0);

const listItem = computed(() => {
  if (current.value >= 0 && current.value < props.list.length) {
    return props.list[current.value];
  }
  return undefined;
});

watch(current, () => {
  router.replace({ query: { id: props.list[current.value].id } });
});

function prev() {
  if (current.value - 1 < 0) {
    current.value = props.list.length - 1;
  } else {
    current.value -= 1;
  }
}
function next() {
  if (current.value + 1 > props.list.length - 1) {
    current.value = 0;
  } else {
    current.value += 1;
  }
}
</script>

<style scoped></style>
