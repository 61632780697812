<template>
  <div class="p-xs-x">
    <Carousel :list="list">
      <template #header="{ item, index }">
        <div class="m-xs-t flex-row justify-space-between">
          <div class="m-xs">Page: {{ item.id }}</div>
          <Button @click="deletePage(index)">Delete</Button>
        </div>
        <LayoutBuilder
          :layout="item.header || []"
          :manifest="layouts.manifest"
          @update:layout="updateHeader($event, index)"
        />
      </template>
      <template #body="{ item, index }">
        <LayoutBuilder
          :layout="item.layout"
          :manifest="layouts.manifest"
          @update:layout="updatePage($event, index)"
        />
      </template>
    </Carousel>

    <div class="m-xs-t">
      <Button @click="addPage">Add Page</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import copy from "@virgodev/bazaar/functions/copy";
import LayoutBuilder from "@virgodev/web-builder/components/LayoutBuilder.vue";
import { useLayoutStore } from "@virgodev/web-builder/stores/layouts";
import Button from "primevue/button";
import { computed } from "vue";
import { useRouter } from "vue-router";
import Carousel from "./Carousel.vue";

export interface CarouselPage {
  id: number;
  layout: any[];
  header: any[];
}
export interface CarouselOptions {
  pages: CarouselPage[];
}

const layouts = useLayoutStore();
const router = useRouter();

const props = defineProps<{
  options: CarouselOptions;
}>();
const emits = defineEmits<{
  update: [Partial<CarouselOptions>];
}>();

const list = computed(() => {
  return props.options.pages;
});

function addPage() {
  const pages = copy(props.options.pages) as CarouselPage[];
  const id =
    (pages.reduce((a: number, b: CarouselPage) => Math.max(a, b.id), 0) || 0) +
    1;
  pages.push({ id, layout: [] });
  emits("update", { pages });
}
function updateHeader(layout: any[], index: number) {
  const pages = copy(props.options.pages) as CarouselPage[];
  pages[index].header = layout;
  emits("update", { pages });
}
function updatePage(layout: any[], index: number) {
  const pages = copy(props.options.pages) as CarouselPage[];
  pages[index].layout = layout;
  emits("update", { pages });
}
function deletePage(index: number) {
  const pages = copy(props.options.pages) as CarouselPage[];
  pages.splice(index, 1);
  emits("update", { pages });
}
</script>

<style scoped></style>
