import { defineStore } from "pinia";
import { ref } from "vue";

export const useWindowStore = defineStore("window", () => {
  const width = ref(window.innerWidth);
  const shiftDown = ref(false);
  const ctrlDown = ref(false);
  function resizeWindow() {
    width.value = window.innerWidth;
  }
  function keyIsDown(event: KeyboardEvent) {
    shiftDown.value = event.shiftKey;
    ctrlDown.value = event.ctrlKey;
  }

  window.onresize = resizeWindow;

  window.onkeydown = keyIsDown;
  window.onkeyup = keyIsDown;

  return { width, shiftDown, ctrlDown };
});
