<template>
  <div v-if="user.isDev" class="panel">
    <Message v-if="error" class="p-zero m-zero">{{ error }}</Message>
    <div v-if="status">
      {{ status }}
    </div>
    <div v-else class="flex-row align-center gap-2xs">
      <InputText v-model="rep" placeholder="impersonate" />
      <Button @click="handleImpersonate"> Go </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import { getConsultant } from "@/utils/reps";
import api from "@virgodev/bazaar/functions/api";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import { ref } from "vue";

const user = useUserStore();

const rep = ref("");
const error = ref("");
const status = ref("");

async function handleImpersonate() {
  error.value = "";
  if (!status.value) {
    const consultant = await getConsultant(rep.value, true);
    if (consultant) {
      status.value = "impersonating";
      let response = await api({
        url: `users/${consultant.id}/impersonate/`,
        method: "POST",
        json: {
          csrfmiddlewaretoken: user.csrf,
        },
        headers: {
          "X-CSRFToken": user.csrf,
        },
      });
      if (response.ok && response.body.status == "ok") {
        await user.logout({ reload: false });
        user.reset();
        user.props.token = response.body.token;
        await user.checkLogin();
        document.location = "/";
      } else {
        error.value = response.body.error || "Failed";
      }
    } else {
      error.value = "Consultant not found";
    }
    status.value = "";
  }
}
</script>

<style scoped>
input {
  width: 9em;
}
.panel {
  background: white;
}
</style>
