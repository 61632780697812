import { uapi } from "@/utils/api";
import { getCeleryResult } from "@/utils/bg";
import { Deferred } from "@/utils/bouncer";
import { APP_TYPE } from "@/utils/settings";
import type { Cart } from "@paparazzi/types/paparazzi.api.serializers";
import { createLogger } from "@paparazzi/utils/debug";
import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { defineStore } from "pinia";
import { computed, nextTick, ref, watch } from "vue";
import { useAddressStore } from "./address";
import { useCardsStore, type AuthorizeCreditCardWithId } from "./cards";
import { useCartStore } from "./cart";
import type { CheckoutMessage } from "./defs/checkout_defs";
import { useFirebaseStore } from "./firebase";
import { usePromosStore } from "./promos";
import { useShopStore } from "./shop";
import { useSocketStore } from "./socket";
import { useUserStore } from "./user";
import { saveCard } from "./utils/save_card";
import {
  serializeItems,
  type SerializedCartItem,
} from "./utils/serialize_cart";

const log = createLogger("checkout");

interface SendCheckoutPacket {
  background_checkout: boolean;
  referer: string;
  shipping_address: number;
  billing_address: number;
  shipping_choice: string;
  use_credits: boolean;
  use_giftcards: boolean;
  use_propay: boolean;
  cart: number;
  coupon_code?: string;
  items: SerializedCartItem[];
  inventory_source: string;
  oos_plan: "";
  cardid?: number;
  credit_card?: string;
  expiration?: string;
  secure_code: string;
}

export const useCheckoutStore = defineStore("checkout", () => {
  const cards = useCardsStore();
  const cart = useCartStore();
  const storage = useLocalStorageStore();
  const promos = usePromosStore();
  const shop = useShopStore();
  const user = useUserStore();
  const firebase = useFirebaseStore();
  const websocket = useSocketStore();
  const addresses = useAddressStore();
  const task = ref("");
  const payment = ref({
    card: undefined,
    save: true,
    cvc: "",
  } as {
    card?: AuthorizeCreditCardWithId;
    save: boolean;
    cvc?: string;
  });
  const newCard = ref(
    undefined as Partial<AuthorizeCreditCardWithId> | undefined,
  );

  const creditOptions = ref({
    list: [
      {
        name: "credits",
        label: "Credits",
        callout: "Use My Credits",
        apply: true,
        amount: 0,
      },
      {
        name: "giftcards",
        label: "Gift Cards",
        callout: "Use My gift Cards",
        updateKey: "cash_total",
        apply: false,
        amount: 0,
      },
      {
        name: "propay",
        label: "ProPay",
        callout: "Use ProPay Premiere Balance",
        apply: false,
        amount: 0,
      },
    ] as {
      name: "credits" | "giftcards" | "propay";
      callout: string;
      label: string;
      apply: boolean;
      amount: number;
      updateKey?: string;
    }[],
    updated: "1970-1-1",
  });
  const messages = ref([] as CheckoutMessage[]);
  const lastUpdate = ref(new Date());
  const completedDeferred = new Deferred<CheckoutMessage>();

  const messageWithCart = computed<Cart | undefined>(() => {
    const msg = messages.value.findLast((m) => m.serialized);
    if (msg) {
      console.log("-> using serialized cart from checkout messages");
      return msg.serialized as Cart;
    }
  });
  const error = computed(() => {
    const msg = messages.value.findLast((m) => m.error);
    if (msg) {
      console.log("-> checkout errored");
      completedDeferred.resolve(msg);
      return msg.error;
    }
  });
  const extra = computed(() => {
    const msg = messages.value.findLast((m) => m.extra);
    if (msg) {
      console.log("-> checkout fulfilled");
      completedDeferred.resolve(msg);
      return msg.extra;
    }
  });

  const balance = computed(() => {
    let total = cart.total;
    let credited = 0;
    const credits: [string, number][] = [];
    for (const row of creditOptions.value.list) {
      const amount = Math.min(total, row.apply ? row.amount : 0);
      if ((row.name !== "propay" || user.isPropayOk) && amount > 0) {
        total -= amount;
        credits.push([row.label, amount]);
        credited += amount;
      }
    }
    return { credits, credited, total };
  });

  watch(cards.list, () => {
    if (payment.value.card) {
      const defaultCard = cards.list.find((c) => c.default);
      if (defaultCard) {
        payment.value.card = defaultCard;
      }
    }
  });
  watch(
    () => payment.value.cvc,
    () => {
      // TODO: remove cvc after a specific amount of time?
    },
  );

  // ensure starting message
  watch(
    messages,
    () => {
      lastUpdate.value = new Date();
      // console.warn("messages", messages.value);
      if (messages.value.length === 0) {
        messages.value.push({ message: "Checking out..." });
      }
    },
    { deep: true },
  );

  watch(
    () => creditOptions.value.updated,
    () => {
      storage.put("creditOptions", creditOptions.value);
    },
  );

  websocket.on("checkout", async (message: CheckoutMessage) => {
    console.log("ws", message);
    if (!message.task_id || message.task_id === task.value) {
      messages.value.push(message);
    } else {
      console.log("ws IGNORED", message);
    }
  });

  async function check(taskId: string | null = null): Promise<CheckoutMessage> {
    if (!cart.object.id) {
      await cart.getCartId();
    }
    if (!cart.object.id) {
      return {
        ready: false,
        message: "Cart not ready [2]",
        error: "Cart Error [1]",
      };
    }

    // is the checkout already happening?
    const response = await api({
      url: "checkout/",
      params: { cart: cart.object.id, task: taskId || "" },
      method: "GET",
    });
    log("check", response);
    if (response.ok) {
      let status = response.body as CheckoutMessage;
      const taskId2 = status.task_id;
      if (taskId2 && taskId2 !== "PENDING") {
        // task.value = taskId2;
        log("waiting for current task", taskId2);
        let result = null;
        try {
          result = celeryToCheckoutMessage(
            await getCeleryResult({ task: taskId2 }),
          );
          // result = celeryToCheckoutMessage({
          //   order: 36371060,
          //   // error: "Sorry, `Jolly Holiday - Multi` is out of stock [0]",
          // });
        } catch (ex) {
          console.error(ex);
        }
        log("result", result);

        if (result) {
          status = result;
        }
      }
      if (status.messages) {
        messages.value = status.messages;
      }
      return status as CheckoutMessage;
    }
    return {
      ready: false,
      status: "error",
      error: "Server Error",
      message: `Failed to communicate with server: ${response.body}`,
    };
  }

  async function submit(): Promise<boolean | undefined> {
    let card = payment.value.card;

    // save billing address
    if (newCard.value?.billing_address && !newCard.value.billing_address.id) {
      newCard.value.billing_address.id = await addresses.save(
        newCard.value.billing_address,
      );
    }

    // save card
    if (newCard.value && payment.value.save) {
      const result = await saveCard(payment.value.cvc || "");
      if (result) {
        messages.value.push({ error: result });
        return;
      } else {
        payment.value.card = newCard.value as AuthorizeCreditCardWithId;
        card = newCard.value as AuthorizeCreditCardWithId;
      }
    } else if (newCard.value) {
      card = newCard.value as AuthorizeCreditCardWithId;
    }

    // PRE-CHECKS
    const shipping = cart.object.shipping_address?.id;
    const billing = card?.billing_address.id || cart.object.billing_address?.id;
    const shipping_choice = `${cart.object.shipping_choice}`;

    if (!card) {
      messages.value = [
        {
          error: "Missing card data",
        },
      ];
      return;
    }

    if (!shipping) {
      messages.value.push({
        error: "Please select a shipping address",
        ok: false,
      });
    }
    if (!billing) {
      messages.value.push({
        error: "Please select a billing address",
        ok: false,
      });
    }
    if (!shipping_choice) {
      messages.value.push({ error: "Please select a shipping method" });
    }

    messages.value = [
      { ok: true, message: "Preparing your order", temporary: true },
    ];
    const ready = await check();

    log("checkout precheck", ready);

    // recent order exists for this cart id already?
    if (ready.extra) {
      messages.value.push({ extra: ready.extra });
      return;
    }

    // cart id is deleted but no order?
    // or cart id wasn't given at all somehow
    if (
      ready.error &&
      ["cart-missing", "cart-deleted"].includes(ready.error_code ?? "")
    ) {
      log("error! getting cart id", cart.object.id);
      await cart.getCartId({ force: true });
      return true;
    }

    // this should always include a cart-missing or cart-deleted error code
    // but i'm leaving it here just in case i missed something
    if (ready.duplicate) {
      const current = cart.object.id;
      log("duplicate without order?: getting new cart id", current);
      await cart.getCartId({ force: true });
      if (current === cart.object.id) {
        messages.value = [
          {
            error: "Duplicate order detected [2]",
            duplicate: true,
          },
        ];
        return;
      } else {
        return true;
      }
    }

    // if an error already exists, show it and shutdown checkout
    if (ready.error) {
      messages.value = [
        {
          error: `${ready.error} [${ready.error_code || "ERR001"}]`,
        },
      ];
      return;
    }

    await nextTick();
    let hasError = messages.value.filter((m) => m.error).length > 0; // TODO: (data.messages || []).find((m) => m.error);

    // if the server is ready then begin checkout
    // otherwise it is already processing this order
    if (!hasError && ready.ready && ready.status != "PENDING") {
      log("preparing checkout packet");

      const opts = creditOptions.value.list;
      const post: SendCheckoutPacket = {
        background_checkout: true,
        referer: APP_TYPE,
        shipping_address: shipping as number,
        billing_address: billing as number,
        shipping_choice: shipping_choice as string,
        use_credits: opts.find((c) => c.name === "credits")?.apply || false,
        use_giftcards: opts.find((c) => c.name === "giftcards")?.apply || false,
        use_propay:
          (user.isPropayOk && opts.find((c) => c.name === "propay")?.apply) ||
          false,
        cart: cart.object.id as number,
        coupon_code: cart.object.coupon_code || promos.couponCode,
        items: serializeItems(cart.object),
        inventory_source: cart.inventorySource as string,
        secure_code: payment.value.cvc as string,
        oos_plan: "",
      };

      if (card?.id) {
        post.cardid = card?.id;
      } else if (card) {
        post.credit_card = card.card;
        post.expiration = `${card.month}/${card.year}`;
      } else {
        console.warn("Missing card data");
      }
      // if (post.oos_plan === "wishlist") {
      //   post.fill = Object.keys(context.rootState.wishlist.items);
      // }
      console.log("checking out", payment.value.cvc, JSON.stringify(post));
      await firebase.lock("checkout", async () => {
        log("submitting checkout");
        completedDeferred.reset();
        let response = await api({
          url: "checkout/",
          json: post,
          method: "POST",
        });
        // let response = {
        //   ok: false,
        //   status: 502,
        //   body: "Internal Server Error",
        // };
        if (!response.ok) {
          log("checkout error", response.body);
          const msg = {
            ready: false,
            status: "error",
            error: "Server Error",
            message: "Connection to the server failed",
          };
          messages.value.push(msg);
          return;
        }

        log("checkout response", response);
        if (response.body.updateCart) {
          log("fixing cart...");
          await cart.sync();
          log("trying again", post);
          log("checkout response", response);
        }

        if (response.body.error && !response.body.message) {
          response.body.ready = false;
          response.body.message = "Server error";
        }

        if (response.body.task) {
          task.value = response.body.task;
          let result: CheckoutMessage = {
            ready: false,
            error: "Server timeout",
          };
          try {
            result = await Promise.race([
              completedDeferred.promise,
              getCeleryResult({
                task: response.body.task,
                maxTimeout: 1000 * 60 * 10,
                dropExceptions: true,
              }),
            ]);
            const message = celeryToCheckoutMessage(result);
            console.log("--> result", result, message);
            if (message) {
              messages.value.push(message);
            }
          } catch (ex) {}
          console.log("checkout celery", result, error.value, extra.value);
          // messages.value.push(result);

          await nextTick();

          if (!error.value && !extra.value) {
            const response = await check(task.value);

            // make sure ws messages haven't come in while this was being checked
            await nextTick();
            if (!error.value && !extra.value) {
              if (response.messages) {
                messages.value = response.messages;
              }
            }
            if (response.duplicate) {
              messages.value.push({ extra: response.extra });
            }
            await nextTick();
            if (
              response.status === "not-running" ||
              (!error.value && !extra.value)
            ) {
              messages.value.push({
                error: "Checkout failed to start, please try again",
              });
            }
            console.log("final response", response);
          }

          if (messageWithCart.value) {
            console.log(
              " -> updated cart from checkout2",
              messageWithCart.value,
            );
            const mergedCart: Cart = copy(messageWithCart.value);
            mergedCart.shipping_address = cart.object.shipping_address;
            mergedCart.billing_address = cart.object.billing_address;
            mergedCart.shipping_choice = cart.object.shipping_choice;
            cart.object = {
              ...mergedCart,
              changed: new Date().toISOString(),
            };
          }
        }
        return;
      });
      return;
    }

    if (!extra.value && !error.value) {
      console.log(
        "check error, but not started?",
        ready,
        extra.value,
        error.value,
      );
      messages.value.push({
        ready: false,
        error: "Not started",
      });
    }
  }
  async function updateCredits(refresh = false) {
    log("credits", "starting update");
    try {
      const updated = new Date(creditOptions.value.updated).getTime();
      if (refresh || updated < Date.now() - 1000 * 60 * 5) {
        let response = await uapi({ url: "credits", json: { refresh } });
        // let response = await api({ url: "credits", json: { refresh } });
        if (response.ok) {
          for (const row of creditOptions.value.list) {
            const key = row.updateKey || row.name;
            if (response.body[key]) {
              row.amount = parseFloat(response.body[key]);
            } else {
              row.amount = 0;
            }
          }
          creditOptions.value.updated = new Date().toISOString();
          log("credits", "updated credits", creditOptions.value);
        } else {
          log("credits", "updated credits failed");
        }
      } else {
        log("credits", "Not updating credits, using cached");
      }
    } catch (ex) {
      console.error("credits", ex);
    }
    log("credits", "done");
  }
  function celeryToCheckoutMessage(result: any): CheckoutMessage | undefined {
    if (result) {
      const retval: Partial<CheckoutMessage> = {
        ...result,
      };
      if (result.order) {
        retval.extra = { order: result.order };
      }

      return retval as CheckoutMessage;
    }
  }

  async function setup() {
    const saved = storage.get("creditOptions", null);
    if (saved) {
      creditOptions.value = saved;
    }
  }

  return {
    cards,
    extra,
    payment,
    messages,
    error,
    task,
    creditOptions,
    balance,
    newCard,
    check,
    submit,
    updateCredits,
    setup,
  };
});
