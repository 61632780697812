<template>
  <div class="product-list">
    <div class="inner" ref="el">
      <p v-if="props.options.categories.length === 0">
        <i>Choose categories...</i>
      </p>
      <h4 v-if="props.options.title">
        {{ props.options.title }}
      </h4>
      <div class="products">
        <RouterLink
          v-for="item of page"
          :key="item.id"
          :to="{ name: 'product-detail', params: { slug: item.slug } }"
          class="product"
        >
          <AutoThumbnail
            v-if="item.images.length > 0"
            :src="item.images[0]['original']"
            :alt="item.name"
            sizes="167px"
            alias="aspect"
          />
          <div v-else class="colorbox"></div>
          <div class="label">{{ item.name }}</div>
        </RouterLink>
        <div
          v-if="pages > 1 && offset > 0"
          @click="offset -= 1"
          class="pager prev"
        >
          <i class="pi pi-chevron-left" />
        </div>
        <div
          v-if="pages > 1 && offset < pages - maxCount"
          @click="offset += 1"
          class="pager next"
        >
          <i class="pi pi-chevron-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "@/stores/defs/shop_defs";
import { useShopStore } from "@/stores/shop";
import { useWindowStore } from "@/stores/window";
import AutoThumbnail from "paparazzi/components/blog/AutoThumbnail.vue";
import { computed, onMounted, ref, watch, watchEffect, type Ref } from "vue";
import type { RouterLink } from "vue-router";

const props = defineProps<{
  options: {
    title: string;
    categories: { category: number }[];
    defaultColor: string;
    bg: string;
    max: number;
    count: number;
  };
}>();
const shop = useShopStore();
const win = useWindowStore();

const el: Ref<HTMLElement | undefined> = ref();
const width = ref(0);
const offset = ref(0);

const categories = computed(() => {
  return props.options.categories.map((item) => item.category);
});

const bg = computed(() => `#${props.options.bg}`);
const defaultColor = computed(() => `#${props.options.defaultColor}`);
const products = ref<Product[]>([]);

const maxCount = computed(() => Math.ceil(width.value / (167 + 24)));
const pages = computed(() => Math.ceil(products.value.length));
const page: Ref<Product[]> = computed(() => {
  if (width.value < 1120) {
    return products.value;
  } else {
    return products.value.slice(offset.value, offset.value + maxCount.value);
  }
});

watchEffect(pickProducts);

watch(
  () => win.width,
  () => {
    measureWidth();
  },
);

onMounted(() => {
  pickProducts();
  measureWidth();
});

function pickProducts() {
  let excludeCategories = shop.categories.filter(
    (cat) => /Blockbusters/gi.test(cat.name) || /Fashion Fix/gi.test(cat.name),
  );
  excludeCategories = excludeCategories.concat(
    shop.categories.filter((cat) =>
      excludeCategories.map((c) => c.id).includes(cat.parent),
    ),
  );

  const exclude = excludeCategories.map((cat) => cat.id);

  // anything still live after 10 days
  const cutoff = new Date(Date.now() - 1000 * 60 * 60 * 24 * 10);
  const oldest = shop.objects.products.filter((item) => {
    const isOld = new Date(item.date_added) < cutoff;
    const isIncluded = item.categories.some((cid: number) =>
      categories.value.includes(cid),
    );
    const isNotExcluded = !item.categories.some((cid: number) =>
      exclude.includes(cid),
    );
    return isOld && isIncluded && isNotExcluded;
  });

  const buckets: { [key: string]: Product[] } = {};
  for (const item of oldest) {
    for (const category of item.categories) {
      if (!buckets[category]) {
        buckets[category] = [];
      }
      buckets[category].push(item);
    }
  }

  const retval = [] as Product[];
  let count = 10;
  while (count > 0) {
    let added = 0;
    for (const cat of categories.value) {
      if (buckets[cat]?.length > 0) {
        const index = Math.random() * buckets[cat].length;
        const [add] = buckets[cat].splice(index, 1);
        retval.push(add);
        count -= 1;
        added += 1;
      }
      if (count <= 0) {
        break;
      }
    }
    if (added === 0) {
      break;
    }
  }
  products.value = retval;
}

function measureWidth() {
  if (el.value) {
    const box = el.value.getBoundingClientRect();
    width.value = box.width;
  }
}
</script>

<style scoped>
.product-list {
  background-color: v-bind("bg");
}
.inner {
  margin: auto;
  max-width: var(--Max-page-widths-max-width);
  display: flex;
  flex-direction: column;
  padding: var(--Sm, 24px) 0px;
  align-items: flex-start;
  align-self: stretch;
}
.product-list h4 {
  width: 100%;
  font-family: Indivisible;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  margin-bottom: var(--Sm, 24px);
}
.inner h4 {
  font-size: 14px;
}

.pager {
  position: absolute;
  height: var(--Component-sizes-SM-, 208px);
  display: flex;
  align-items: center;
}
.page i {
  height: 8px;
  font-size: 10px;
}
.pager.prev {
  left: -40px;
  padding-right: 30px;
}
.pager.next {
  right: -40px;
  padding-left: 30px;
}

.products {
  position: relative;
  display: grid;
  grid-template-columns: repeat(v-bind("page.length"), 1fr);
  grid-template-rows: 1fr;
  gap: 24px;
  max-width: 100%;
  min-height: calc(var(--Component-sizes-SM-, 208px) + 14px);
}

.product img,
.colorbox {
  min-width: 160px;
  height: var(--Component-sizes-SM-, 208px);
  background-color: #333;
  object-fit: cover;
}

.product {
  display: block;
  max-width: 167px;
}

.product .label {
  color: var(--Black, #000);
  text-align: center;
  font-family: Indivisible;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 6px;
  transition: 0.2s ease;
  font-weight: 400;
  text-shadow: none;
}

.product:hover .label {
  font-weight: 400;
  text-shadow: #000 0 0.25px;
}

@media (max-width: 1200px) {
  .products {
    min-height: 0;
    overflow: auto;
  }
  .pager {
    display: none;
  }
}

@media (max-width: 1119px) and (min-width: 900px) {
  .product-list h4 {
    padding-left: var(--Sm, 24px);
  }
  /* .products .product:first-of-type {
    padding-left: var(--Sm, 24px);
  }
  .products .product:last-of-type {
    padding-right: var(--Sm, 24px);
  } */
}

@media (max-width: 1119px) {
  .products {
    padding: 0 var(--Sm, 24px);
  }
  .product-list h4 {
    padding-left: var(--Sm, 24px);
  }
}

@media (max-width: 800px) {
  .product-list {
    padding: 0;
  }
  /* .product-list .product:first-child {
    padding-left: var(--Sm, 24px);
  } */
  /* .product-list h4 {
    text-align: center;
    padding-left: 0;
  } */
  .product img,
  .colorbox {
    min-width: 122px;
    width: var(--Component-sizes-2XS, 122px);
    height: 152.5px;
  }

  .product {
    max-width: 122px;
  }
}
</style>
