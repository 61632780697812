type EnabledDict = Record<string, boolean>;

let index = 0;
const colors = ["skyblue", "lime", "yellow", "red", "orange"];
const enabled: EnabledDict = {};

function starAlts(name: string): string[] {
  const parts = name.split(".");
  const starAltNames: string[] = [];

  for (let i = 0; i < parts.length; i++) {
    starAltNames.push(`${parts.slice(0, i + 1).join(".")}`);
    starAltNames.push(`${parts.slice(0, i).concat(["*"]).join(".")}`);
  }
  return starAltNames;
}

export function isEnabled(name: string): boolean {
  for (const star of starAlts(name)) {
    if (enabled[star]) {
      return true;
    }
  }
  return false;
}

export function createLogger(name: string) {
  index += 1;
  const myIndex = index;
  const color = colors[index % colors.length];
  const bg = "black";
  return (...msg) => {
    if (isEnabled(name)) {
      console.log(
        "%c" + name,
        `color: ${color}; background: ${bg}; padding: 2px 4px; border-radius: 4px`,
        ...msg,
      );
    }
  };
}

export function enable(name: string, clear = false) {
  if (clear) {
    for (const key in enabled) {
      delete enabled[key];
    }
  }
  for (const star of name.split(",")) {
    enabled[star.trim()] = true;
  }
}

export function disable(name: string) {
  for (const star of name.split(",")) {
    enabled[star.trim()] = false;
  }
}

if (localStorage.debug) {
  enable(localStorage.debug);
}
