<template>
  <AutoForm
    @update:modelValue="emit('update:modelValue', $event)"
    @submit="handleSubmit"
    :modelValue="props.modelValue"
    :fields="props.fields"
    :defaults="props.defaults"
    :sub="props.sub"
    :errors="props.errors"
    :form-component="props.formComponent"
  >
    <template
      #product="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>{{ field.label || field.name }}</label>
      <div>
        <Dropdown
          @update:model-value="updateField($event)"
          :model-value="object[field.name]"
          :options="
            shop.products.map((i: any) => ({ name: i.name, value: i.id }))
          "
          optionLabel="name"
          optionValue="value"
          placeholder="None Selected"
        />
      </div>
    </template>

    <template
      #category="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>{{ field.label || field.name }}</label>
      <div>
        <Dropdown
          @update:model-value="updateField($event)"
          :model-value="object[field.name]"
          :options="
            shop.categories.map((c: any) => ({
              name: categoryPath(c),
              value: c.id,
            }))
          "
          optionLabel="name"
          optionValue="value"
          placeholder="None Selected"
        />
      </div>
    </template>

    <template
      #masked="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>
        {{ field.label || field.name }}
        <i
          v-if="field.extra.tooltip"
          class="pi pi-question-circle m-2xs-l"
          v-tooltip.top="field.extra.tooltip"
        />
      </label>
      <div>
        <InputMask
          :mask="field.extra?.mask"
          :auto-clear="false"
          :class="field.css_classes"
          :placeholder="field.placeholder"
          :slot-char="field.extra?.slotChar"
          :model-value="object[field.name]"
          @update:modelValue="updateField($event)"
          :required="field.required"
          :autocomplete="field.autocomplete"
          :pt="{
            root: {
              pattern: field.pattern,
              minlength: field.minlength,
              name: field.name,
            },
          }"
        />
      </div>
    </template>

    <template
      #ccnum="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>
        {{ field.label || field.name }}
      </label>
      <div>
        <CreditCardInput
          @update:modelValue="updateField($event)"
          :name="field.name"
          :model-value="object[field.name]"
          :required="field.required"
        />
      </div>
    </template>

    <template
      #phone="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>
        {{ field.label || field.name }}
        <i
          v-if="field.extra?.tooltip"
          class="pi pi-question-circle m-2xs-l"
          v-tooltip.top="field.extra.tooltip"
        />
      </label>
      <div>
        <InputMask
          mask="999-999-9999"
          placeholder="___-___-____"
          :pt="{
            root: {
              name: field.name,
              minlength: 10,
              pattern: '[^_]+',
            },
          }"
          :auto-clear="false"
          :unmask="true"
          :slot-char="'_'"
          :model-value="object[field.name]"
          @update:modelValue="updateField($event)"
          :required="field.required"
          :autocomplete="field.autocomplete"
        />
        <ErrorList
          v-if="props.errors"
          :errors="props.errors ? props.errors[field.name] : []"
        />
      </div>
    </template>

    <template
      #image="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>{{ field.label || field.name }}</label>
      <div>
        <AutoThumbnail
          v-if="object[field.name]"
          :src="object[field.name]"
          alt="uploaded image"
          alias="aspect"
          sizes="150px"
        />
        <div class="p-2xs-y" v-if="object[field.name]">
          {{ object[field.name].split("/").at(-1) }}
        </div>
        <FileUpload
          @uploader="handleStartUpload($event, updateField)"
          :name="field.name"
          :multiple="false"
          :customUpload="true"
          :maxFileSize="10000000"
          :auto="true"
          accept="image/*"
          mode="basic"
        >
        </FileUpload>
      </div>
    </template>

    <template
      #checkbox="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label class="flex-row gap-sm checkbox">
        <Checkbox
          :modelValue="object[field.name]"
          :binary="true"
          :readonly="field.readonly"
          :required="field.required"
          :disabled="field.disabled"
          @update:modelValue="updateField($event)"
        />
        {{ field.label }}
      </label>
    </template>

    <template
      #new-password="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>
        {{ field.label || field.name }}
        <i
          v-if="field.extra?.tooltip"
          class="pi pi-question-circle m-2xs-l"
          v-tooltip.top="field.extra.tooltip"
        />
      </label>
      <div class="password-container">
        <Password
          @update:model-value="
            clearValidity(field);
            updateField($event);
          "
          @blur="checkPassword(field, object[field.name], updateField)"
          :placeholder="field.placeholder"
          :modelValue="object[field.name]"
          :required="field.required"
          :autocomplete="field.autocomplete"
          :toggleMask="true"
          append-to=".password-container"
          :pt="{
            input: {
              root: {
                required: field.required,
                name: field.name,
                autocomplete: field.autocomplete,
                minlength: field.minlength,
              },
            },
          }"
        >
          <template #header>
            <div class="font-semibold text-xm mb-4">Pick a password</div>
          </template>
          <template #footer>
            <Divider />
            <ul class="pl-2 ml-2 my-0 leading-normal">
              <li>At least one lowercase</li>
              <li>At least one uppercase</li>
              <li>At least one numeric</li>
              <li>Minimum 8 characters</li>
            </ul>
          </template>
        </Password>
        <div v-if="field.help" class="help">{{ field.help }}</div>
        <div v-if="props.errors && props.errors[field.name]" class="error">
          <template v-if="props.errors[field.name] instanceof Array">
            <div v-for="error of props.errors[field.name]">
              {{ error }}
            </div>
          </template>
          <template v-else>
            {{ errors[field.name] }}
          </template>
        </div>
      </div>
    </template>

    <template
      #username="{
        object,
        field,
        updateField,
      }: {
        object: any;
        field: FieldDef;
        updateField: (value: any) => void;
      }"
    >
      <label>
        {{ field.label || field.name }}
        <i
          v-if="field.extra?.tooltip"
          class="pi pi-question-circle m-2xs-l"
          v-tooltip.top="field.extra.tooltip"
        />
      </label>
      <div class="username-container">
        <InputText
          @update:modelValue="updateField($event)"
          @blur="checkUsername(field, object[field.name], updateField)"
          :name="field.name"
          :model-value="object[field.name]"
          :minlength="4"
          :maxlength="150"
          :required="field.required"
          autocomplete="username"
        />
        <!-- pattern="[A-za-z0-9@.+_\\-]{4,150}" -->
        <div class="help p-xs-t">
          Use Letters, digits and these special characters: @ . + - _
        </div>
        <ErrorList
          v-if="props.errors"
          :errors="props.errors ? props.errors[field.name] : []"
        />
      </div>
    </template>

    <template #default>
      <slot name="default" />
    </template>

    <template
      v-for="(index, name) in $slots"
      v-slot:[name]="{ field, updateField }"
    >
      <slot :name="name" :field="field" :updateField="updateField" />
    </template>
  </AutoForm>
</template>

<script setup lang="ts">
import { type Category } from "@/stores/defs/shop_defs";
import { useShopStore } from "@/stores/shop";
import {
  validatePassword,
  validateUsername,
} from "@/utils/password_validation";
import api from "@virgodev/bazaar/functions/api";
import AutoForm from "@virgodev/vue-models/components/forms/AutoForm.vue";
import type { FieldDef } from "@virgodev/vue-models/components/forms/defs";
import ErrorList from "@virgodev/vue-models/components/forms/ErrorList.vue";
import AutoThumbnail from "paparazzi/components/blog/AutoThumbnail.vue";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import FileUpload, { type FileUploadUploaderEvent } from "primevue/fileupload";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { ref, type Component } from "vue";
import CreditCardInput from "./CreditCardInput.vue";

const shop = useShopStore();

const prevAttemptedUsername = ref("");

const props = defineProps<{
  fields: FieldDef[];
  modelValue: any;
  formComponent?: Component;
  errors?: any;
  defaults?: any;
  sub?: boolean;
}>();
const emit = defineEmits(["submit", "update:modelValue"]);

async function handleStartUpload(
  event: FileUploadUploaderEvent,
  update: (value: any) => void,
) {
  if (event.files instanceof Array) {
    for (const file of event.files) {
      const data = new FormData();
      data.append("file", file);

      const response = await api({
        url: "thumbnail/",
        body: data,
        method: "POST",
      });
      if (response.ok) {
        update(response.body.uploads[0]);
      }
    }
  }
}

function categoryPath(category: Category): string {
  const parent = shop.categories.find((c) => c.id === category.parent);
  if (parent) {
    return `${categoryPath(parent)} > ${category.name}`;
  }
  return category.name;
}

function clearValidity(field: FieldDef) {
  const el: HTMLInputElement | null = document.querySelector(
    `[name=${field.name}]`,
  );
  if (el) {
    el.setCustomValidity("");
  }
}

function checkPassword(field: FieldDef, value: string, updateField: Function) {
  const el: HTMLInputElement | null = document.querySelector(
    `[name=${field.name}]`,
  );
  if (el) {
    const error = validatePassword(value);
    el.setCustomValidity(error);
  }
  updateField(value);
}

async function checkUsername(
  field: FieldDef,
  value: string,
  updateField: Function,
) {
  if (prevAttemptedUsername.value !== value) {
    prevAttemptedUsername.value = value;
    const el: HTMLInputElement | null = document.querySelector(
      `[name=${field.name}]`,
    );
    if (el) {
      el.setCustomValidity("Checking username...");
      const error = await validateUsername(value);
      el.setCustomValidity(error);
    }
  }
  updateField(value);
}

function handleSubmit($event: SubmitEvent) {
  emit("submit", $event);
}
</script>
