import { defineStore } from "pinia";

import type { Product } from "@/stores/defs/shop_defs";
import { useShopStore } from "@/stores/shop";
// import debug from "debug";
import copy from "@virgodev/bazaar/functions/copy";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import debug from "debug";
import { computed, nextTick, ref, watch, type Ref } from "vue";
import { useFirebaseStore } from "./firebase";
import { useUserStore } from "./user";

const log = debug("wishlist");
const MAX_WISHLIST = 1000;

export const useWishlistStore = defineStore("wishlist", () => {
  const shop = useShopStore();
  const storage = useLocalStorageStore();
  const firebase = useFirebaseStore();
  const user = useUserStore();

  const items: Ref<{ [index: string]: number }> = ref(
    storage.get("wishlist", {}) || {},
  );
  const saved: Ref<number> = ref(storage.get("wishlist-saved", -1) || -1);
  const synced: Ref<number> = ref(storage.get("wishlist-synced", -1) || -1);
  const updated = ref<string>("");

  watch(
    items,
    () => {
      if (items.value.empty) {
        delete items.value.empty;
      }

      if (Object.keys(items.value).length >= MAX_WISHLIST) {
        while (Object.keys(items.value).length >= MAX_WISHLIST) {
          const keys = Object.keys(items.value);
          const index = Math.floor(Math.random() * keys.length);
          delete items.value[keys[index]];
        }
      }
      if (!user.props.id) {
        log("save anon items");
        storage.put("wishlistAnon", items.value);
      }
      storage.put("wishlist", items.value);
      saved.value = Date.now();
      storage.put("wishlist-saved", saved.value);
    },
    { deep: true },
  );
  watch(updated, () => {
    if (updated.value) {
      log("saving wishlist");
      firebase.update("wishlist", updated.value, items.value);
    }
  });
  watch(
    () => firebase.userData,
    () => {
      if (firebase.shouldUpdate("wishlist", updated.value)) {
        log("wishlist updated");
        items.value = {
          ...(firebase.userData.wishlist ?? {}),
        };

        updated.value = firebase.userData.timestamps.wishlist;
      }
      const anonItems = storage.get("wishlistAnon", {}) || {};
      if (Object.keys(anonItems).length > 0) {
        log("adding anon-items", copy(items.value), anonItems);
        items.value = {
          ...items.value,
          ...anonItems,
        };
        log("added anon-items", copy(items.value));

        storage.put("wishlistAnon", {});
        save();
      }
    },
  );
  watch(
    () => user.props.id,
    async () => {
      items.value = {};
      storage.put("wishlist", {});
      updated.value = "";
      log("wishlist cleared on login change");

      await nextTick();
      if (user.props.id) {
        if (firebase.userData.wishlist) {
          items.value = {
            ...firebase.userData.wishlist,
          };
        } else {
          items.value = {};
        }
      }
    },
  );

  const count = computed(() => {
    return Object.values(items.value).reduce((a, b) => a + b, 0);
  });

  async function findMissingInvalidWishlistItems() {
    let removed = [];
    let invalid = [];
    let products = shop.allowedProducts;
    for (let key of Object.keys(items.value)) {
      const id = parseInt(key);
      if (id) {
        let product: Product | undefined = products.find((p) => p.id == id);
        if (!product || !product.active) {
          removed.push(id);
        }
      } else {
        invalid.push(key);
      }
    }
    return { removed, invalid };
  }

  async function clean() {
    // wait for products to load
    await shop.ready();

    let { removed, invalid } = await findMissingInvalidWishlistItems();

    if (removed.length > 0 || invalid.length > 0) {
      for (const id of removed) {
        delete items.value[id];
        log("removed item", id);
      }
      for (const key of invalid) {
        console.warn("invalid id", key);
        log("invalid id", key);
        delete items.value[key];
      }

      save();
    }
  }

  function save() {
    updated.value = new Date().toISOString();
  }

  return {
    items,
    count,
    synced,
    saved,
    clean,
    save,
  };
});
