import { useRepsStore, type RepWithId } from "@/stores/user";
import api from "@virgodev/bazaar/functions/api";

export async function getConsultant(
  consultantId: string,
  force: boolean = false,
): Promise<RepWithId | undefined> {
  const cleaned = consultantId.trim().replace(/\/$/i, "");
  const reps = useRepsStore();
  const is_hack = /\.php/gi.test(cleaned);
  let rep = undefined;
  if (!force) {
    rep = reps.list.find((r) => `${r.rep_number}` === `${cleaned}`);
  }
  if (!is_hack && !rep) {
    const response = await api({
      url: "profile/rep-info/",
      method: "POST",
      json: { url_or_number: cleaned },
    });
    if (response.ok && response.body.rep) {
      rep = response.body.rep as RepWithId;

      // has it already synced?
      reps.list.push(rep);
      if (reps.store) {
        await reps.store.setItem(`${rep.id}`, rep);
      }
    }
  }
  return rep;
}
