<template>
  <div
    class="bg"
    :style="{
      'background-image': `url(${props.src})`,
      // 'background': `image-set(${bgs})`,
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import api from "@virgodev/bazaar/functions/api";
import { Ref, computed, ref, watch } from "vue";

const props = defineProps({
  src: String,
  alias: { type: String, default: "square" },
  sizes: { type: String, default: "500px" },
});

interface Src {
  src: string;
  options: { size: [number, number] };
}

const srcset: Ref<Src[]> = ref([]);

const bgs = computed(() => {
  return srcset.value.map((s) => `${s.src} ${s.options.size[0]}w`).join(", ");
});

watch(
  props,
  async () => {
    const response = await api({
      url: "thumbnail/",
      params: { image: props.src, alias: props.alias },
      method: "GET",
      options: {
        // cache: 'no-cache',
        cache: "default",
      },
    });
    if (response.ok) {
      srcset.value = response.body;
    }
  },
  { immediate: true },
);
</script>

<style scoped>
.bg {
  background-size: cover;
}
</style>
