import type { Order } from "@paparazzi/types/paparazzi.api.serializers";
import { createStore } from "@virgodev/vue-models/utils/create_store";

export interface OrderWithId extends Order {
  id: number;
}

export const useOrderStore = createStore<OrderWithId>("orders", {
  syncTimeout: 1000 * 60 * 60 * 2,
});
