<template>
  <div
    :class="{
      [props.options.align]: true,
      [`mobile-${props.options.mobile_align}`]: true,
      [`size-${props.options.size}`]: true,
      hideMobile: !props.options.show_text_mobile,
    }"
    ref="el"
    class="annoucement"
    :style="
      props.options.use_content_bg
        ? {
            '--bg': `#${props.options.content_bg}`,
            '--mbg': `#${props.options.content_mobile_bg || props.options.content_bg}`,
            '--fg': `#${props.options.content_fg}`,
            '--mfg': `#${props.options.content_mobile_fg}`,
          }
        : {}
    "
  >
    <AutoBackground
      v-if="props.options.background"
      :class="{ 'no-content-bg': !props.options.use_content_bg }"
      :src="props.options.background"
      class="img img-desktop"
    />
    <AutoBackground
      v-if="props.options.backgroud || props.options.background_mobile"
      :class="{ 'no-content-bg': !props.options.use_content_bg }"
      :src="props.options.background_mobile"
      class="img img-mobile"
    />
    <div class="content">
      <div class="content-bg" />
      <div 
        class="text"
        :style="{ position: `${props.options.position}`, top: `${props.options.position_relative_top}px`, right: `${props.options.position_relative_right}px`, bottom: `${props.options.position_relative_bottom}px`, left: `${props.options.position_relative_left}px`  }"
      >
        <h1 v-if="props.options.title"
          class="hero-title"
          :style="{ width: `${props.options.title_text_width}px`, maxWidth: `${props.options.title_max_width}px` }"
        >
          {{ props.options.title || "Title" }}
        </h1>
        <h2
          v-if="props.options.short"
          class="short"
          :style="{ width: `${props.options.short_text_width}px` }"
        >
          {{ props.options.short || "Short" }}
        </h2>
        <h2 v-if="!props.options.title && !props.options.short">
          Announcement is ready to configure
        </h2>
        <a v-if="props.options.link" :href="props.options.link">
          <Button
            :class="{
              [props.options.content_button_style]: true,
              'desktop:block': true,
              'mobile:none': true,
            }"
            >{{ props.options.prompt || "Go" }}</Button
          >
          <Button
            :class="{
              [props.options.content_mobile_button_style]: true,
              'desktop:none': true,
              'mobile:block': true,
            }"
            >{{ props.options.prompt || "Go" }}</Button
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AutoBackground from "paparazzi/components/blog/AutoBackground.vue";
import Button from "primevue/button";
import { computed } from "vue";

const props = defineProps({
  options: { type: Object, required: true },
});

const imgX = computed(() => `${props.options.bg_justify * 100}%`);
const imgY = computed(() => `${(1 - props.options.bg_alignment) * 100}%`);
</script>

<style scoped>
.annoucement {
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: stretch;
  justify-content: stretch;
  margin: 0 auto;
  max-width: var(--Max-page-widths-max-width);
}
.annoucement.size-xl {
  height: var(--Component-sizes-xl, 440px);
}
.annoucement.size-large {
  height: var(--Component-sizes-lg, 380px);
}
.annoucement.size-normal {
  height: var(--Component-sizes-md, 280px);
}
.annoucement.size-xl .content {
  /* min-width: calc(min(60vw, var(--Component-sizes-xl, 440px))); */
}
.annoucement.size-large .content {
  min-width: calc(min(60vw, var(--Component-sizes-lg, 380px)));
}
.annoucement.size-normal .content {
  min-width: calc(min(60vw, var(--Component-sizes-md, 280px)));
}
.annoucement > * {
  grid-column: 1/-1;
  grid-row: 1/1;
}

.img {
  display: block;
  overflow: hidden;
  flex: 1 1 100%;
  z-index: 1;
  height: 100%;
  min-width: 1px;
  max-width: 100vw;
  background-position: v-bind("imgX") v-bind("imgY");
  background-repeat: no-repeat;
}
.img-mobile {
  display: none;
}
.img.no-content-bg {
  grid-column: 1/-1 !important;
}
.content {
  z-index: 3;
  margin: 0;
  display: grid;
  grid-template-columns: auto;
  justify-content: stretch;
}
.content > * {
  grid-row: 1/1;
  grid-column: 1/1;
}

.content-bg {
  width: 100%;
  height: 100%;
  align-self: stretch;
  min-width: 0;
  max-width: 100vw;
  background-color: var(--bg);
}

.content .text {
  margin: auto;
  z-index: 2;
  display: grid;
  gap: var(--Sm, 24px);
  padding: var(--Sm, 24px);
  align-items: center;
  align-self: stretch;
  max-width: 282px;
  position: initial;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.content .text,
.content .text h1,
.content .text h2 {
  color: var(--fg);
}
.content .text > * {
  margin: 0;
}

.content .text h1 {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin: auto;
  max-width: var(--Component-sizes-md, 280px);
  text-align: center;

  /* Heading 2 */
  font-family: Indivisible;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.content .text h2 {
  text-transform: none;
}

.content .text .short {
  margin: auto;
  align-self: stretch;
  text-align: center;
  /* Paragraph 2 */
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* color is controlled by the inline editor */
/* .annoucement.size-large.right :deep(.content h1) {
  color: #fff;
} */

.annoucement.size-large.right :deep(.content h2.short) {
  /* color: #fff; */
  text-transform: none;
}

/* .content .text p.short  {
  color: #fff;
} */

.content .text a {
  margin: auto;
}
.annoucement.size-xl.right,
.annoucement.size-xl.left {
  grid-template-columns: 1fr 1fr;
}
.annoucement.right {
  grid-template-columns: 1fr auto;
}
.annoucement.left .img {
  grid-column: 2/2;
}
.annoucement.left .content {
  grid-column: 1/1;
}
.annoucement.right .img {
  grid-column: 1/1;
}
.annoucement.right .content {
  grid-column: 2/2;
}

.annoucement.center .content {
  place-self: center;
  margin: auto;
}

.p-button {
  font-weight: 300;
  text-transform: capitalize;
}

@media (max-width: 800px) {
  .content .text,
  .content .text h1,
  .content .text h2 {
    color: var(--mfg, "red") !important;
  }
  .content .text {
    padding: var(--Sm, 24px);
    gap: 12px;
    max-width: 100%;
    position: initial;
    inset: 0!important;
    h1 {
      width: 100%!important;
    }
    .short {
      width: 100%!important;
    }
    h2 {
      font-weight: 500;
    }
  }
  .content-bg {
    background-color: var(--mbg);
  }
  .hideMobile .content .short {
    display: none;
  }
  .annoucement .img {
    grid-column: 1/-1 !important;
  }
  .annoucement .content {
    min-width: 0 !important;
    /* width: auto !important; */
    place-self: center;
    margin: auto;
    margin: var(--Sm, 24px);
  }
  #app .annoucement.size-xl .content {
    width: 70vw;
  }
  .annoucement.mobile-left {
    grid-template-columns: auto 1fr;
  }
  .annoucement.mobile-right {
    grid-template-columns: 1fr auto;
  }
  .annoucement.mobile-left .content {
    grid-column: 1/1;
  }
  .annoucement.mobile-right .content {
    grid-column: 2/2;
  }
  .annoucement.mobile-center .content {
    grid-column: 1/-1;
  }

  .annoucement.mobile-below.size-xl .bg {
    height: 390px !important;
  }
  .annoucement.mobile-below.size-large .bg {
    height: 280px !important;
  }
  .annoucement.mobile-below {
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    gap: 0 !important;
    height: auto !important;
    margin-bottom: 20px;
    .bg {
      padding: 0;
      margin: 0;
    }

    .content {
      grid-column: auto;
      grid-row: auto;
      margin: 0;
      padding: 0 !important;
      width: 100vw !important;
    }
  }
}
@media (max-width: 700px) {
  .annoucement .img-desktop {
    display: none;
  }
  .annoucement .img-mobile {
    display: block;
  }
  .annoucement.size-xl.right {
    height: 390px;
  }
  .annoucement.size-large.right {
    height: 280px;
    .content .text {
      gap: var(--2XS, 8px);
    }

    h1 {
      font-size: 24px;
    }
  }
  .bg.img.img-mobile {
    background-position: top;
  }
}
</style>
