<template>
  <svg
    :width="option?.box?.width"
    :height="option?.box?.height"
    :viewBox="getViewBox(option?.box)"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="path in option.paths"
      :d="path"
      :fill="option.conf?.fill"
      :stroke="option?.conf?.stroke"
      :stroke-miterlimit="option?.conf?.miterlimit"
      :stroke-linecap="option.conf?.linecap"
      :stroke-width="option.conf?.width"
    />
  </svg>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { computed, ref } from "vue";

const props = withDefaults(defineProps<{
  name: string;
  strokeWidth?: number;
  fill?: string;
}>(), {
  strokeWidth: 0.75,
  fill: 'none',
});

interface Conf {
  fill?: string | undefined,
  linecap?: "round" | "butt" | "square" | "inherit" | undefined
  miterlimit?: GLfloat | undefined,
  stroke?: string | undefined,
  width?: GLfloat | undefined,
}

const defaultConf = {
  miterlimit: 10,
  stroke: "currentColor",
}

function makeConf(c?: Conf): Conf {
  return { ...defaultConf,  ...c }
}

interface ViewBox {
  height?: GLint,
  width?: GLint,
  xpos?: GLint,
  ypos?: GLint,
}

const defaultBox = {
  height: 30,
  width: 32,
  xpos: 0,
  ypos: 0,
}

function makeViewBox(box?: ViewBox): ViewBox {
  return {...defaultBox, ...box}
}

function getViewBox(box: ViewBox = {}): string {
  return `${box.xpos || 0} ${box.ypos || 0} ${box.width || 32} ${box.height || 30}`
}

interface VectorImage {
  box?: ViewBox | undefined,
  conf?: Conf | undefined,
  paths: string[];
}

const defaultVectorImg = {
  box: makeViewBox(),
  conf: makeConf(),
  paths: [],
}

function makeVectorImage(vi?: VectorImage): VectorImage {
  return { ...defaultVectorImg, ...vi }
}

const options:Ref<{[index:string]: VectorImage}> = ref({
  bag: {
    paths: [
      "M21.8701 22.38H9.14009C8.68009 22.38 8.32009 21.97 8.38009 21.52L9.67009 11.55C9.72009 11.17 10.0401 10.88 10.4301 10.88H20.5801C20.9601 10.88 21.2901 11.17 21.3401 11.55L22.6301 21.51C22.6901 21.97 22.3301 22.37 21.8701 22.37V22.38Z",
      "M12.0898 10.79C12.0898 8.91 13.6198 7.38 15.4998 7.38C17.3798 7.38 18.9098 8.91 18.9098 10.79",
    ]
  },
  crown: {
    box: {
      width: 24,
      ypos: -5
    },
    conf: {
      fill: "black",
      stroke: undefined,
    },
    paths: [
      "M11.9986 6.48571L12.267 6.2046C12.4714 5.98737 14.2347 4.10905 14.2347 2.97183C14.2347 1.74517 13.2381 0.748505 12.0114 0.748505C10.7847 0.748505 9.78809 1.74517 9.78809 2.97183C9.78809 4.10905 11.5514 6.00015 11.7559 6.2046L12.0242 6.48571H11.9986ZM11.9986 1.47684C12.8292 1.47684 13.4936 2.15406 13.4936 2.97183C13.4936 3.53405 12.6631 4.67127 11.9986 5.42515C11.3342 4.67127 10.5036 3.53405 10.5036 2.97183C10.5036 2.14128 11.1809 1.47684 11.9986 1.47684Z",
      "M20.0877 17.5257H3.91112C3.70668 17.5257 3.55334 17.6918 3.55334 17.8835C3.55334 18.0751 3.71945 18.2412 3.91112 18.2412H20.0877C20.2922 18.2412 20.4455 18.0751 20.4455 17.8835C20.4455 17.6918 20.2794 17.5257 20.0877 17.5257Z",
      "M20.5863 10.8812C20.1647 10.8812 19.7813 10.9707 19.4235 11.124C19.9474 10.4596 20.2541 9.61622 20.2541 8.709C20.2541 6.54957 18.4908 4.78624 16.3313 4.78624C14.1719 4.78624 12.4086 6.54957 12.4086 8.709V16.7079H20.5863C22.1963 16.7079 23.4996 15.4045 23.4996 13.7945C23.4996 12.1845 22.1963 10.8812 20.5863 10.8812ZM16.3313 5.5529C18.0691 5.5529 19.4874 6.97123 19.4874 8.709C19.4874 10.4468 18.0691 11.8651 16.3313 11.8651C14.5936 11.8651 13.1752 10.4468 13.1752 8.709C13.1752 6.97123 14.5936 5.5529 16.3313 5.5529ZM16.3313 12.6445C16.9958 12.6445 17.6091 12.4784 18.1585 12.1845C17.8519 12.6445 17.673 13.2068 17.673 13.7945C17.673 13.8201 17.673 13.8456 17.673 13.8712C17.6091 13.769 17.5324 13.6795 17.443 13.6029C17.098 13.2579 16.638 13.0662 16.1524 13.0662C15.6669 13.0662 15.2069 13.2579 14.8619 13.6029C14.6191 13.8456 13.6225 15.1873 13.1625 15.839V11.0345C13.878 12.0057 15.028 12.6445 16.3186 12.6445H16.3313ZM14.0313 15.954C14.6063 15.1745 15.2708 14.2929 15.4113 14.1395C15.6158 13.9351 15.8713 13.8329 16.1524 13.8329C16.4336 13.8329 16.7019 13.9479 16.8936 14.1395C17.098 14.344 17.2002 14.5995 17.2002 14.8806C17.2002 15.1618 17.0852 15.4301 16.9063 15.6218C16.6763 15.839 16.2802 15.9284 16.1269 15.954H14.0186H14.0313ZM17.6347 15.954C17.8263 15.6984 17.9413 15.4045 17.9669 15.0851C18.133 15.4173 18.3502 15.7112 18.6185 15.954H17.6219H17.6347ZM20.5863 15.954C19.398 15.954 18.4397 14.9829 18.4397 13.8073C18.4397 12.6318 19.4108 11.6607 20.5863 11.6607C21.7619 11.6607 22.733 12.6318 22.733 13.8073C22.733 14.9829 21.7619 15.954 20.5863 15.954Z",
      "M0.499634 13.7945C0.499634 15.4045 1.80296 16.7079 3.41296 16.7079H11.5907V8.709C11.5907 6.54957 9.82738 4.78624 7.66794 4.78624C5.5085 4.78624 3.74518 6.54957 3.74518 8.709C3.74518 9.61622 4.06462 10.4596 4.57573 11.124C4.21795 10.9707 3.82184 10.8812 3.41296 10.8812C1.80296 10.8812 0.499634 12.1845 0.499634 13.7945ZM10.824 8.72178C10.824 10.4596 9.40571 11.8779 7.66794 11.8779C5.93017 11.8779 4.51184 10.4596 4.51184 8.72178C4.51184 6.98401 5.93017 5.56568 7.66794 5.56568C9.40571 5.56568 10.824 6.98401 10.824 8.72178ZM10.824 11.0345V15.839C10.3513 15.2001 9.36738 13.8456 9.1246 13.6029C8.7796 13.2579 8.3196 13.0662 7.83405 13.0662C7.3485 13.0662 6.8885 13.2579 6.5435 13.6029C6.45405 13.6923 6.37739 13.7818 6.3135 13.8712C6.3135 13.8456 6.3135 13.8201 6.3135 13.7945C6.3135 13.194 6.13461 12.6445 5.82795 12.1845C6.37739 12.4784 6.99072 12.6445 7.65516 12.6445C8.94571 12.6445 10.0957 12.0057 10.8113 11.0345H10.824ZM7.85961 15.954C7.70627 15.9284 7.31016 15.839 7.08016 15.6218C6.8885 15.4301 6.78628 15.1618 6.78628 14.8806C6.78628 14.5995 6.90128 14.3312 7.09294 14.1395C7.29739 13.9351 7.55294 13.8329 7.83405 13.8329C8.11516 13.8329 8.38349 13.9479 8.57516 14.1395C8.72849 14.2929 9.38015 15.1745 9.95515 15.954H7.84683H7.85961ZM5.38073 15.954C5.64906 15.7112 5.87906 15.4173 6.03239 15.0851C6.07072 15.4045 6.18572 15.6984 6.36461 15.954H5.36795H5.38073ZM1.2663 13.7945C1.2663 12.6062 2.2374 11.6479 3.41296 11.6479C4.58851 11.6479 5.55961 12.619 5.55961 13.7945C5.55961 14.9701 4.58851 15.9412 3.41296 15.9412C2.2374 15.9412 1.2663 14.9701 1.2663 13.7945Z",
    ],
  },
  menu: {
    box: {
      width: 22,
      ypos: -6
    },
    conf: {
      linecap: "round",
      stroke: "currentColor",
      width: 0.75
    },
    paths: [
      "M0.5 0.5H21.5",
      "M0.5 7.5H21.5",
      "M0.5 14.5H21.5"
    ]
  },
  profile: {
    paths: [
      "M15.6299 14.92C17.712 14.92 19.3999 13.2321 19.3999 11.15C19.3999 9.06789 17.712 7.38 15.6299 7.38C13.5477 7.38 11.8599 9.06789 11.8599 11.15C11.8599 13.2321 13.5477 14.92 15.6299 14.92Z",
      "M8.37988 22.38C8.37988 18.38 11.6299 15.13 15.6299 15.13C19.6299 15.13 22.8799 18.38 22.8799 22.38",
    ],
  },
  search: {
    paths: [
      "M14.2501 19.13C17.4976 19.13 20.1301 16.4974 20.1301 13.25C20.1301 10.0026 17.4976 7.37 14.2501 7.37C11.0027 7.37 8.37012 10.0026 8.37012 13.25C8.37012 16.4974 11.0027 19.13 14.2501 19.13Z",
      "M18.48 17.45L23.4 22.38",
    ],
  },
  wishlist: {
    paths: [
      "M15.5231 9.0431C10.1031 3.6231 3.6231 10.1031 9.0431 15.5231L15.5231 22.0031L22.0031 15.5231C27.3931 10.1331 20.9131 3.6631 15.5231 9.0431Z",
    ],
  },
});

const option = computed(() => {
  return makeVectorImage(options.value[props.name] || {})
});
</script>

<style scoped>
svg {
  stroke-width: 0.75;
}
svg:hover {
  stroke-width: 1.5;
}
</style>
